<script>
import InfoTooltip from "@/components/widgets/info-tooltip.vue";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    components: {      
        InfoTooltip  
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12">
            <label for="usage_limit">Límite de uso por cupón</label>
            <InfoTooltip class="ml-2" title="Cuántas veces se puede utilizar este cupón antes de que se anule." />
            <input
                id="usage_limit"
                v-model="value.usage_limit"
                type="number"
                class="form-control"
                placeholder="Uso ilimitado"
                :class="{ 'is-invalid': v.coupon.usage_limit.$error && submitted }"
            />
        </div>
        <div class="form-group col-12">
            <label for="usage_limit_per_user">Límite de uso por usuario</label>
            <InfoTooltip class="ml-2" title="Cuántas veces puede utilizar este cupón un solo usuario. Utiliza el correo electrónico de facturación para los invitados y el ID de usuario para los usuarios registrados." />
            <input
                id="usage_limit_per_user"
                v-model="value.usage_limit_per_user"
                type="number"
                class="form-control"
                placeholder="Uso ilimitado"
                :class="{ 'is-invalid': v.coupon.usage_limit_per_user.$error && submitted }"
            />
        </div>
    </div>
</template>