<script>
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12">
            <label for="code">Código del cupón</label>
            <input id="code" v-model="value.code" type="text" class="form-control" placeholder="Ingresa un código"
                :class="{ 'is-invalid': v.coupon.code.$error && submitted }" />
            <div v-if="!v.coupon.code.required && submitted" class="invalid-feedback">El código es requerido</div>
        </div>
        <div class="form-group col-12">
            <label for="description">Descripción</label>
            <textarea id="description" v-model="value.description" maxlength="225" rows="3" placeholder="Ingresa una descripción" class="form-control"
                :class="{ 'is-invalid': v.coupon.description.$error && submitted }"
            ></textarea>
            <div v-if="v.coupon.description.$error && submitted" class="invalid-feedback">Descripción inválida</div>
        </div>
    </div>
</template>