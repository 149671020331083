<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
    <i 
      class="mdi mdi-information-outline" v-if="title"
      :title="title"
      v-b-tooltip.hover
    ></i>
</template>