<script>
import Status from "@/helpers/general/status";
import InfoTooltip from "@/components/widgets/info-tooltip.vue";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            coupon_discount_types: Status.getList('coupon_discount_types'),
        }
    },
    components: {      
        InfoTooltip  
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12">
            <label for="discount_type">Tipo de descuento</label>
            <select v-model="value.discount_type" class="form-control"
                :class="{ 'is-invalid': v.coupon.discount_type.$error && submitted }">
                <option v-for="option in coupon_discount_types" v-bind:value="option.id" :key="option.id">
                    {{ option.label }}
                </option>
            </select>
        </div>
        <div class="form-group col-12">
            <label for="amount">Importe del cupón</label>
            <input
                id="amount"
                v-model="value.amount"
                type="text"
                class="form-control"
                placeholder="Ingresa el precio regular del coupono"
                :class="{ 'is-invalid': v.coupon.amount.$error && submitted }"
            />
        </div>
        <div class="form-group col-12">
            <label for="date_expires">Fecha de caducidad del cupón</label>
            <InfoTooltip class="ml-2" title="El cupón caducará a las 00:00:00 de esta fecha." />
            <input
                id="date_expires"
                v-model="value.date_expires"
                type="date"
                class="form-control"
                placeholder="Ingresa la fecha de caducidad del cupón"
                :class="{ 'is-invalid': v.coupon.date_expires.$error && submitted }"
            />
        </div>
        
        <div class="form-group col-12">
            <label for="free_shipping">Permitir el envío gratuito</label>
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    v-model="value.free_shipping"
                    class="custom-control-input"
                    id="free_shipping"
                    :class="{ 'is-invalid': v.coupon.free_shipping.$error && submitted }"
                />
                <label class="custom-control-label" for="free_shipping">Marca esta casilla si el cupón ofrece envío gratuito. El método de envío gratuito debe estar activo en tu zona de envío y requerir «un cupón válido de envío gratuito» (ver el ajuste «El envío gratuito requiere»).</label>
            </div>
        </div>
    </div>
</template>