<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Agregar',
    },
    label: {
      type: String,
      default: 'Agregar',
    },
    type: {
      type: String,
      default: 'button',
    },
    btnClass: {
      type: String,
      default: 'btn btn-success btn-sm waves-effect waves-light'
    }
  },
  emits:['click'],
  methods: {
    click(){
      this.$emit('click');
    }
  }
};
</script>

<template>
    <button
        :class="btnClass"
        :type="type"
        @click="click()"
        :title="title"
        v-b-tooltip.hover
    >
        <i class="mdi mdi-plus"></i>
        {{ label }}
    </button>
</template>