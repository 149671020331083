<script>
export default {
    props: {
        value: Array,
        stores: Array,
        coupon_slug: String,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    components: {        
    },
    methods: {
        addRow(){
            this.value.push({
                id: '',
            });
        },
        deleteRow(key){
            this.value.splice(key, 1);
        },
    },
};
</script>

<template>
    <table class="table table-bordered table-sticky-header">
        <thead>
            <tr>
                <th scope="col">Tienda</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(store, i) in value" :key="i" :set="vstore = v.coupon.stores.$each[i]" class="">
                <td>
                    <div class="form-group">
                        <select v-model="store.id" class="form-control"
                            :class="{ 'is-invalid': vstore.id.$error && submitted }">
                            <option value="">Select</option>
                            <option v-for="option in stores" v-bind:value="option.id" :key="option.id">
                                {{ option.name }}
                            </option>
                        </select>                                            
                    </div>
                </td>
                <td class="text-center">
                    <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Eliminar"
                        @click="deleteRow(i)"
                        v-if="!store?.is_update"
                    >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="4" class="text-right">
                    <a
                        href="javascript:void(0);"
                        class="text-success"
                        @click="addRow()"
                    >
                        <i class="mdi mdi-plus-circle font-size-18"></i> Agregar tienda
                    </a>
                </td>
            </tr>
        </tfoot>
    </table>
</template>