<script>
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import GeneralPartial from "./partials/general-partial.vue";
import GeneralTabPartial from "./partials/tabs/general-tab-partial.vue";
import StoreTabPartial from "./partials/tabs/store-tab-partial.vue";
import UseLimitTabPartial from "./partials/tabs/use-limit-tab-partial.vue";
import RestrictionTabPartial from "./partials/tabs/restriction-tab-partial.vue";

import {
    storeComputed,
} from "@/state/helpers";

const initialState = () => {
    return {
        submitted: false,
        uploadModalId:'upload-modal',
        coupon: {
            code: null,
            description: null,
            discount_type: 'fixed_cart',
            amount: null,
            date_expires: null,
            usage_count: null,
            individual_use: null,
            free_shipping: null,
            minimum_amount: null,
            maximum_amount: null,
            email_restrictions: null,
            usage_limit: null,
            usage_limit_per_user: null,
            limit_usage_to_x_items: null,
            exclude_sale_items: null,

            product_ids: [],
            products: [],

            exclude_product_ids: [],
            exclude_products: [],

            product_category_ids: [],
            product_categories: [],

            exclude_product_category_ids: [],
            exclude_product_categories: [],

            stores: [],
        },
        
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        GeneralPartial,
        GeneralTabPartial,
        UseLimitTabPartial,
        StoreTabPartial,
        RestrictionTabPartial
    },
    computed: {
        ...storeComputed,
    },
    validations: {
        coupon: {
            code: {required},
            description: {},
            discount_type: {},
            amount: {},
            date_expires: {},
            usage_count: {},
            individual_use: {},
            free_shipping: {},
            minimum_amount: {},
            maximum_amount: {},
            email_restrictions: {},
            usage_limit: {},
            usage_limit_per_user: {},
            limit_usage_to_x_items: {},
            exclude_sale_items: {},
            stores: {
                $each: {
                    id: {required},
                }
            },
        }
    },
    methods: {
        wrapCouponStores(coupon) {
            if(coupon?.stores){
                const stores = coupon?.stores.map(store => {
                    store.pivot.id = store.pivot.store_id;
                    store.pivot.is_update = true;
                    return store?.pivot;
                });
                coupon.stores = stores;
            }
        },        
        async shown() {
            this.$store.dispatch("store/loadList");
            if (this.id) {
                const response = await api.get({
                    url: `coupons/${this.id}`,
                    config: {
                        withLoading: true
                    }  
                });
                const coupon = response?.data?.data;
                
                this.wrapCouponStores(coupon);

                this.coupon = Object.assign({}, this.coupon, coupon);
            }
        },
        
        
        async confirm() {

            console.log(this.coupon);

            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            const data = this.coupon;

            data.product_ids = data.products.map(product => product.id);
            data.exclude_product_ids = data.exclude_products.map(product => product.id);
            data.product_category_ids = data.product_categories.map(product_category => product_category.id);
            data.exclude_product_category_ids = data.exclude_product_categories.map(product_category => product_category.id);

            await api.save({
                url: 'coupons',
                id: this.coupon?.id,
                data: data,
                config: {
                    withLoading: true
                }                
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess");
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
        showUploadModal() {
            this.$bvModal.show(this.uploadModalId);
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de cupón'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row" v-if="!id || coupon?.id">
            <div class="col-lg-3 col-md-12 mb-2 coupon-form-left-column">
                <div class="card mb-0 h-100">
                    <div class="card-body">
                        <h4 class="card-title">General</h4>
                        <p class="card-title-desc">
                            Ingresa los datos generales del cupón
                        </p>
                        <GeneralPartial v-model="coupon" :v="$v" :submitted="submitted"></GeneralPartial>
                    </div>
                </div>
            </div>

            <div class="col-lg-9 col-md-12 mb-2 coupon-form-right-column">
                <div class="card mb-0">
                    <div class="card-body">
                        <h4 class="card-title form-inline">
                            Datos del cupón
                        </h4>
                        <p class="card-title-desc">
                            Personaliza las caracteristicas del cupon
                        </p>
                        <b-tabs
                            pills
                            vertical
                            nav-class="p-0"
                            nav-wrapper-class="col-sm-3 coupon-form-tabs-list"
                            content-class="px-2 text-muted"
                            class="coupon-form-tabs"
                            >
                            <b-tab title="General" active title-item-class="mb-2">
                                <b-card-text>
                                    <GeneralTabPartial v-model="coupon" :v="$v" :submitted="submitted"></GeneralTabPartial>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Restricción de uso" title-item-class="mb-2">
                                <b-card-text>
                                    <RestrictionTabPartial v-model="coupon" :v="$v" :submitted="submitted"></RestrictionTabPartial>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Limites de uso" title-item-class="mb-2">
                                <b-card-text>
                                    <UseLimitTabPartial v-model="coupon" :v="$v" :submitted="submitted"></UseLimitTabPartial>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Tiendas">
                                <b-card-text>
                                    <StoreTabPartial v-model="coupon.stores" :stores="stores" :coupon_slug="coupon.slug" :v="$v" :submitted="submitted"></StoreTabPartial> 
                                </b-card-text>
                            </b-tab>
                        </b-tabs> 
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>